<template>
    <div class="container">
         <div class="mt-2 fl-te-c card">
            <div class="row">
                <div class="col-6">
            <btn text="<-" class="" @click="$router.go(-1)" size="sm"/></div>
                <div class="col-6">
            <h4 class="text-primary mb-0 font-poppins-semibold">{{ details.interview_data.title }}</h4>
                    </div>
            </div>
             </div>
        <div class="mt-2 mb-2 fl-te-c card">
            <div class="row">

<!--                    <div class="col-lg-6 mt-3">-->
<!--                        <span class="font-jost-semi-bold fs-lg-1"><b>Interview Type&nbsp;:&nbsp;</b></span>-->
<!--                        {{ details.interview_data.interview_type_name }}-->
<!--                    </div>-->
<!--                    <div class="col-lg-6 mt-3">-->
<!--                        <span class="font-jost-semi-bold fs-lg-1"><b>Interview Topics&nbsp;:&nbsp;</b></span>-->
<!--                       {{ details.interview_data.interview_topic_names }}-->
<!--                    </div>-->
                    <div class="col-lg-6">
                        <span class="font-jost-semi-bold fs-lg-1"><b>No of Questions&nbsp;:&nbsp;</b></span>
                        <b>{{ details.interview_data.no_of_questions }}</b>
                    </div>
                    <div class="col-lg-6">
                        <span class="font-jost-semi-bold fs-lg-1"><b>Enabled Questions&nbsp;:&nbsp;</b></span>
                        <b>{{ details.interview_data.count }}</b>
                    </div>
                </div>
<!--            <div v-if="details.question_data.length===0">-->
<!--                <btn size="sm" text="Generate Questions" icon="fa fa-plus" loading-text="Generating...."-->
<!--                     @click="GenerateQuestions()" :loading="loading"></btn>-->
<!--            </div>-->
            </div>
                 <div class="card mt-2">
             <!--Tabs-->
                    <tabs class="c-tabs-light">
                        <tab title="Topics" class="font-lato-medium">
                            <div>
                                <Topics :details="details.topic_data" :interview="details.interview_data.id"  @reloadInterviewDetails="loadDetails"></Topics>
                            </div>
                        </tab>
                        <tab title="Questions" class="font-lato-medium">
                            <div>
                                <Questions :interview="details.interview_data.id" @reloadInterviewDetails="loadDetails"></Questions>
                            </div>
                        </tab>
                        <tab title="Candidates" class="font-lato-medium">
                            <div>
                                <Candidates :interview="details.interview_data.id" @reloadInterviewDetails="loadDetails"></Candidates>
                            </div>
                        </tab>
                        <tab title="Results" class="font-lato-medium">
                            <div>
                                <Results :interview="details.interview_data.id" @reloadInterviewDetails="loadDetails"></Results>
                            </div>
                        </tab>

                    </tabs>

                </div>

        </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import Questions from '../../../views/mcq-interview/Interview/Questions';
import Topics from '../../../views/mcq-interview/Interview/Topics';
import Results from '../../../views/mcq-interview/Interview/Results';
import Candidates from '../../../views/mcq-interview/Interview/Candidates';

export default {
    name: 'Interview Details',
    components: {
        Questions, Candidates, Topics, Results
    },
    data () {
        return {
            loading: false,
            questionData: '',
            result_data: '',
            details: '',
            questions: '',
            candidates: '',
            id: this.$route.params.id
        };
    },
    mounted () {
        this.loadDetails();
    },
    watch: {
        id: {
            handler () {
                this.loadDetails();
            }
        }
    },
    methods: {
        async GenerateQuestions () {
            this.loading = true;
            const response = await axios.form(urls.mcq_interview.interview.generateQuestions, { id: this.id });
            const result = response.data;
            if (result.success) {
                this.$notify(' Questions Generated Successfully', 'Success', { type: 'success' });
                this.loading = false;
                this.loadDetails();
            } else {
                const errors = response.data.errors;
                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        let errorMessage = '';
                        if (key === '__all__') {
                            errorMessage = `${errors[key]}`;
                        } else {
                            errorMessage = `${key}:  ${errors[key]}`;
                        }
                        this.$notify(errorMessage, 'Warning', { type: 'warning' });
                    }
                }
                this.loading = false;
                this.loadDetails();
            }
        },
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.mcq_interview.interview.details, { id: this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                this.questionData = this.details.question_data;
                this.result_data = this.details.result_data;
                console.log('----------- Question Data-----', this.result_data);
                console.log('----------- interview-----', typeof (this.details.interview_data.id));
            } else {
                if (json.message) {
                    this.$notify(json.message, 'Error', {
                        type: 'danger'
                    });
                    this.$router.push('/mcq-interview/interview/');
                } else {
                    this.$notify('Could not load data, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
            }
            this.loading = false;
        }

    }
};
</script>

<style scoped>

</style>
