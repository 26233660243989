<template>
    <div>
        <div class="mt-1">
            <s-form>
                <div class="row">
                    <div class="col-6">

                            <validated-vue-select class="c-input-select"
                                                   :options="candidateOptions" @input="clearData"
                                                   label="Candidate" v-model="model.candidate"></validated-vue-select>

                    </div>
                    <div class="col-6">

                            <validated-vue-select class="c-input-select"
                                                   :options="questionFilterOptions" @input="clearData"
                                                   label="Answered Filter" v-model="model.question_filter"></validated-vue-select>

                    </div>
                    </div>
                <div class="col-12 text-left mt-4">
                    <div class="btn-group">
                            <btn text="Show" @click="showResult" :loading="loading" loading-text="Loading..." class="px-4"></btn>
                            <btn text="Download" @click="downloadResult" :loading="loading" loading-text="Loading..." class="px-4"></btn>
                        </div>
                        </div>
            </s-form>
        </div>

                <div class="mt-2 details-container" v-if="resultData">
          <table class="details-table">
            <tr>
              <td><strong>Candidate</strong></td>
              <td><strong>:</strong></td>
              <td>{{ resultData.candidate_name }}</td>
              <td><strong>Total Questions</strong></td>
              <td><strong>:</strong></td>
              <td>{{ resultData.total_questions }}</td>
            </tr>
            <tr>
              <td><strong>Email</strong></td>
              <td><strong>:</strong></td>
              <td>{{ resultData.candidate_email }}</td>
              <td><strong>Marks Obtained</strong></td>
              <td><strong>:</strong></td>
              <td>{{ resultData.mark_obtained }}</td>

            </tr>
              <tr>
              <td><strong>Result %</strong></td>
              <td><strong>:</strong></td>
              <td>{{ resultData.result_percentage }}%</td>

                <td><strong>Generated At</strong></td>
              <td><strong>:</strong></td>
              <td>{{ resultData.generated_at }}</td>
            </tr>
          </table>

          <div class="skill-grading">
            <h3>Skill Grading</h3>
            <table class="skill-table">
              <thead>
                <tr>
                  <th>Skill</th>
                  <th>Total Questions</th>
                  <th>Correct Answers</th>
                  <th>Accuracy (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, skill) in parsedSkillGrading" :key="skill">
                  <td>{{ skill }}</td>
                  <td>{{ data.total_questions }}</td>
                  <td>{{ data.correct_answers }}</td>
                  <td>{{ data.accuracy }}%</td>
                </tr>
              </tbody>
            </table>
          </div>

                    <div class="mt-2 question-container" v-if="answerData">
      <div v-for="(item, index) in answerData" :key="item.id" class="question-card">
          <div class="row">
          <div class="col-lg-10">
                <p><b>{{ index + 1 }}. {{ item.question }}</b></p>
                   <ul class="options-list" v-if="showChoices===index">
                      <li v-for="(choice, choiceIndex) in item.choices" :key="choiceIndex">
                        <label>
<!--                          <input type="radio" :name="'question-' + index" :value="choice" />-->
                         - {{ choice }}
                        </label>
                      </li>
                       <p><b>Correct Answer:</b> {{ item.correct_answer }}</p>
                    </ul>
              <p><b>Answer Given:</b> {{ item.answer }}</p>
                <p>Question Fetched At: {{ item.qn_fetched_at }}</p>
                <p>Answered At: {{ item.answered_at }}</p>
                <p>Time Taken: {{ item.time_taken }}</p>
              </div>
               <div class="col-lg-2 text-right">
                   <div class="row">
                       <div>
                        <span v-if="item.ans_result" class="text-success">✔️</span>
                        <span v-else class="text-danger">❌</span></div>
                   <div v-if="!item.ans_result" class="ml-2">
                        <btn size="sm" :class="showChoices === index ? 'fa fa-eye-slash' : 'fa fa-eye'" @click="toggleChoices(index)">

                        </btn>
                       </div>
                       </div>
                   </div>
          </div>
      </div>
          </div>

                <div>
                </div>
            </div>

    </div>
</template>
<script>

import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'Results',
    showModal: false,
    answers: [],
    loading: false,
    props: {
        interview: {
            type: Number,
            required: true

        }
    },
    data () {
        return {
            selectedResult: null,
            candidateOptions: [],
            questionFilterOptions: [
                { label: 'All', value: 'All' },
                { label: 'Correct', value: 'Correct' },
                { label: 'Wrong', value: 'Wrong' }

            ],
            model: {
                candidate: null,
                question_filter: null
            },
            result: null,
            resultData: null,
            answerData: null,
            showChoices: null
        };
    },
    mounted () {
        this.loadCandidateOptions();
    },

    methods: {
        clearData () {
            console.log('clearData triggered');
            this.result = null;
            this.resultData = null;
            this.answerData = null;
        },
        toggleChoices (index) {
            // Toggle between opening and closing the options
            this.showChoices = this.showChoices === index ? null : index;
        },
        showResultDetails (result) {
            this.selectedResult = result;
            this.$refs.resultDetails.show();
        },
        async loadCandidateOptions () {
            this.loading = true;
            console.log('----------- ID---------------------------------', this.interview);
            try {
                const response = await axios.form(urls.mcq_interview.interview_candidate.select, { interview: this.interview });
                this.candidateOptions = response.data.data;
            } catch (e) {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }

            this.loading = false;
        },
        async showResult () {
            if (!this.model.candidate) {
                this.$notify('Select Candidate', 'Warning', {
                    type: 'warning'
                });
                return;
            }
            this.loading = true;
            const response = await axios.form(urls.mcq_interview.interview.resultDetails, { candidate: this.model.candidate, ans_filter: this.model.question_filter });
            const json = response.data;
            if (json.error === false) {
                this.result = json.data;
                this.resultData = this.result.result_data;
                this.answerData = this.result.result_data.answers;
            } else {
                if (json.message) {
                    this.$notify(json.message, 'Error', {
                        type: 'danger'
                    });
                    this.$router.push('/mcq-interview/interview/');
                } else {
                    this.$notify('Could not load data, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
            }
            this.loading = false;
        },
        async downloadResult () {
            this.loading = true;
            try {
                const response = await axios.form(urls.mcq_interview.interview.downloadResults, { candidate: this.model.candidate, ans_filter: this.model.question_filter, interview: this.interview });
                const json = response.data;
                if (json.error === false) {
                    this.loading = false;
                    window.open(json.url);
                } else {
                    this.loading = false;
                    this.$notify(json.message, 'Oops!', { type: 'warning' });
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        ViewAnswers (result) {
            this.selectedResult = result;
            this.$refs.answerDetails.show();
        },

        detailsClose () {
            this.selectedResult = null;
        }
    },
    computed: {
        parsedSkillGrading () {
            try {
                // Ensure valid JSON format by replacing single quotes with double quotes
                const validJson = this.resultData.skill_grading.replace(/'/g, '"');
                return JSON.parse(validJson);
            } catch (e) {
                console.error('Error parsing skill grading:', e);
                return {};
            }
        }
    }

};
</script>

<style scoped>
.skill-grading {
  margin-top: 15px;
}

.skill-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.skill-table th,
.skill-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.skill-table th {
  background-color: #f1f1f1;
  font-weight: bold;
}
.details-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.details-table {
  width: 100%;
  margin-bottom: 15px;
}

.details-table td {
  padding: 8px;
}
</style>
