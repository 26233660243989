<!--<template>-->
<!--  <div v-if="details">-->

<!--    <div class="question-container">-->
<!--      <div v-for="(item, index) in details" :key="index" class="question-card">-->
<!--          <div class="row">-->
<!--          <div class="col-lg-9">-->
<!--        <b>{{ index + 1 }}. {{ item.question }}</b>-->
<!--        <ul class="options-list">-->
<!--          <li v-for="(choice, choiceIndex) in item.choices" :key="choiceIndex">-->
<!--            <label>-->
<!--              <input type="radio" :name="'question-' + index" :value="choice" />-->
<!--              {{ choice }}-->
<!--            </label>-->
<!--          </li>-->
<!--        </ul>-->
<!--          <b>Correct Answer: {{ item.correct_ans }}</b>-->
<!--              </div>-->
<!--          <div class="col-lg-3">-->
<!--            <div v-if="item.status==='Enabled'">-->
<!--                        <btn color="success"-->
<!--                             @click="statusChange(item.id, item.interview_id)"-->
<!--                             icon="fa fa-toggle-off"></btn>-->
<!--                       <span> Enabled</span>-->
<!--                    </div>-->
<!--                    <div v-else>-->
<!--                        <btn color="danger"-->
<!--                             @click="statusChange(item.id, item.interview_id)"-->
<!--                             icon="fa fa-toggle-on"></btn>-->
<!--                        <span> Disabled</span>-->
<!--                    </div>-->
<!--              </div>-->
<!--              </div>-->
<!--      </div>-->

<!--    </div>-->
<!--  </div>-->
<!--  <div v-else>-->
<!--    <p>Loading questions...</p>-->
<!--  </div>-->
<!--</template>-->
<template>
  <div v-if="questionData && questionData.length">
    <div v-for="(topic, topicIndex) in questionData" :key="topicIndex" class="topic-container">
      <h2 class="mt-2 topic-title">{{ topic.topic_name }}</h2>

      <div v-for="(subTopic, subTopicIndex) in topic.sub_topics" :key="subTopicIndex" class="sub-topic-container">
        <h3 class="mt-2 mb-2 ml-5 sub-topic-title">{{ subTopic.sub_topic_name }}</h3>

        <div class="question-container">
          <div v-for="(item, index) in subTopic.questions" :key="index" class="question-card">
            <div class="row">
              <div class="col-lg-9">
                <b>{{ index + 1 }}. {{ item.question }}</b>
                <ul class="options-list">
                  <li v-for="(choice, choiceIndex) in item.choices" :key="choiceIndex">
                    <label>
                      <input type="radio" :name="'question-' + item.id" :value="choice" />
                      {{ choice }}
                    </label>
                  </li>
                </ul>
                <b>Correct Answer: {{ item.correct_ans }}</b>
              </div>
              <div class="col-lg-3">
                <div v-if="item.status === 'Enabled'">
                  <btn color="success" @click="statusChange(item.id, item.interview_id)" icon="fa fa-toggle-off"></btn>
                  <span> Enabled</span>
                </div>
                <div v-else>
                  <btn color="danger" @click="statusChange(item.id, item.interview_id)" icon="fa fa-toggle-on"></btn>
                  <span> Disabled</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div v-else>
    <p>Loading questions...</p>
  </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'Questions',
    props: {
        interview: {
            type: Number,
            required: true

        }
    },
    data () {
        return {
            loading: false,
            questionData: '',
            questions: ''
        };
    },
    mounted () {
        this.loadQuestions();
    },
    methods: {
        async loadQuestions () {
            this.loading = true;
            console.log('----------- ID---------------------------------', this.interview);
            const response = await axios.form(urls.mcq_interview.interview.questionDetails, { id: this.interview });
            const json = response.data;
            if (json.error === false) {
                this.questions = json.data;
                this.questionData = this.questions.question_data;
                // this.result_data = this.details.result_data;
                console.log('----------- Question Data-----', this.questionData);
                // console.log('----------- interview-----', typeof (this.details.interview_data.id));
            } else {
                if (json.message) {
                    this.$notify(json.message, 'Error', {
                        type: 'danger'
                    });
                    this.$router.push('/mcq-interview/interview/');
                } else {
                    this.$notify('Could not load data, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
            }
            this.loading = false;
        },
        async statusChange (itemId, interviewId) {
            this.loading = true;
            const response = await axios.form(urls.mcq_interview.interview.questionStatusChange, { id: itemId });
            const result = response.data;
            if (result.success) {
                this.$notify('Status Changed Successfully', 'Success', { type: 'success' });
                this.$emit('reloadInterviewDetails');
            } else {
                if (result.message) {
                    this.$notify(result.message, 'Warning', { type: 'warning' });
                }
                const errors = response.data.errors;
                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        let errorMessage = '';
                        if (key === '__all__') {
                            errorMessage = `${errors[key]}`;
                        } else {
                            errorMessage = `${key}:  ${errors[key]}`;
                        }
                        this.$notify(errorMessage, 'Warning', { type: 'warning' });
                    }
                }
                this.loading = false;
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>
/* Topic Title - Blue */
.topic-title {
  color: blue;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Sub-topic Title - Green */
.sub-topic-title {
  color: green;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}
</style>
