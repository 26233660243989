<template>
  <div v-if="candidateData">
      <div class="text-right">
                          <btn size="sm" text="Allocate Candidate" icon="fa fa-plus"
                     @click="$refs.allocatecandidate.show();" :loading="loading"></btn>
                     </div>
          <modal ref="allocatecandidate" :no-close-on-backdrop="true" title="Candidates" width="40r" header-color="primary" :show-additional-buttons="true">
            <template #buttons>
                <a @click="candidateAddClose"><i class="fa fa-close"></i></a>
            </template>

            <s-form @submit="candidateAdd">
                <div>
                    <validated-ajax-vue-select multiple :url="candidateSelectUrl" label="Candidates" v-model="candidateList"></validated-ajax-vue-select>

                </div>
                 <div class="col-12 text-right mt-2">
                        <div class="btn-group">
<!--                            <btn text="Generate" @click="GenerateQuestions" :loading="editLoading" loading-text="Generating Questions..." class="px-4"></btn>-->
                            <btn text="Allocate" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                        </div>
                        </div>
            </s-form>
          </modal>

<!--    <h2 class="text-xl font-bold mb-4">Interview Results</h2>-->
    <table class="full-width">
      <thead>
        <tr class="tc-heading-center">
          <th class="border border-gray-300 px-4 py-2">Candidate Name</th>
          <th class="border border-gray-300 px-4 py-2">Email</th>
<!--          <th class="border border-gray-300 px-4 py-2">Token</th>-->
          <th class="border border-gray-300 px-4 py-2">Status</th>
          <th class="border border-gray-300 px-4 py-2">Action</th>
          <th class="border border-gray-300 px-4 py-2">Mark</th>
            <th class="border border-gray-300 px-4 py-2">Result</th>

        </tr>
      </thead>
      <tbody>
        <tr v-for="(result, index) in candidateData" :key="index">
          <td class="border border-gray-300 px-4 py-2">{{ result.candidate_name }}</td>
          <td class="border border-gray-300 px-4 py-2">{{ result.candidate_email }}</td>
<!--          <td class="border border-gray-300 px-4 py-2">{{ result.token }}</td>-->
          <td class="border border-gray-300 px-4 py-2">{{ result.status }}</td>
            <td align="center">
                <btn size="sm" v-if="result.send_mail_flag" text="Email" class="iconsminds-email" color="grey"  @click="emailSend(result.candidate_id,result.interview_id,result.id,index)" :loading="loadingIndex === index"  loading-text="Sending..."></btn>
                <btn size="sm" v-else text="Email" class="iconsminds-email" @click="emailSend(result.candidate_id,result.interview_id,result.id,index)" :loading="loadingIndex === index"  loading-text="Sending..."></btn>
            </td>
            <td class="border border-gray-300 px-4 py-2">{{ result.mark_obtained }}</td>
            <td align="center">
<!--                <div class="btn-group">-->
                    <btn size="sm" class="fa fa-eye" @click="showResultDetails(result.id)"></btn>
<!--                    <btn size="sm" class="fa fa-list" @click="ViewAnswers(result.interview_result.answers)"></btn>-->
<!--                </div>-->
            </td>

        </tr>
      </tbody>
    </table>

      <modal v-if="result" ref="resultDetailsModal" :no-close-on-backdrop="true" title="Result Details" width="80r" header-color="primary" :show-additional-buttons="true">
      <template #buttons>
        <a @click="detailsClose" class="close-btn"><i class="fa fa-close"></i></a>
      </template>
<!--          {{selectedResult}}-->
      <s-form>
          <div class="row">
          <div class="col-6">
           <validated-vue-select class="c-input-select"
                                                   :options="questionFilterOptions" @input="showResultDetails(resultData.interview_candidate_id)"
                                                   label="Answered Filter" v-model="question_filter"></validated-vue-select>
          </div>

            <div class="col-6 mt-4">
            <btn text="Download" @click="downloadResult(resultData.interview_candidate_id)" :loading="loading" loading-text="Loading..." class="px-4"></btn>
          </div>
              </div>
          <div class="mt-2 details-container" v-if="resultData">
          <table class="details-table">
            <tr>
              <td><strong>Candidate</strong></td>
              <td><strong>:</strong></td>
              <td>{{ resultData.candidate_name }}</td>
              <td><strong>Total Questions</strong></td>
              <td><strong>:</strong></td>
              <td>{{ resultData.total_questions }}</td>
            </tr>
            <tr>
              <td><strong>Email</strong></td>
              <td><strong>:</strong></td>
              <td>{{ resultData.candidate_email }}</td>
              <td><strong>Marks Obtained</strong></td>
              <td><strong>:</strong></td>
              <td>{{ resultData.mark_obtained }}</td>

            </tr>
              <tr>
              <td><strong>Result %</strong></td>
              <td><strong>:</strong></td>
              <td>{{ resultData.result_percentage }}%</td>

                <td><strong>Generated At</strong></td>
              <td><strong>:</strong></td>
              <td>{{ resultData.generated_at }}</td>
            </tr>
          </table>

          <div class="skill-grading">
            <h3>Skill Grading</h3>
            <table class="skill-table">
              <thead>
                <tr>
                  <th>Skill</th>
                  <th>Total Questions</th>
                  <th>Correct Answers</th>
                  <th>Accuracy (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, skill) in parsedSkillGrading" :key="skill">
                  <td>{{ skill }}</td>
                  <td>{{ data.total_questions }}</td>
                  <td>{{ data.correct_answers }}</td>
                  <td>{{ data.accuracy }}%</td>
                </tr>
              </tbody>
            </table>
          </div>

                    <div class="mt-2 question-container" v-if="answerData">
      <div v-for="(item, index) in answerData" :key="item.id" class="question-card">
          <div class="row">
          <div class="col-lg-10">
                <p><b>{{ index + 1 }}. {{ item.question }}</b></p>
                   <ul class="options-list" v-if="showChoices===index">
                      <li v-for="(choice, choiceIndex) in item.choices" :key="choiceIndex">
                        <label>
<!--                          <input type="radio" :name="'question-' + index" :value="choice" />-->
                         - {{ choice }}
                        </label>
                      </li>
                       <p><b>Correct Answer:</b> {{ item.correct_answer }}</p>
                    </ul>
              <p><b>Answer Given:</b> {{ item.answer }}</p>
                <p>Question Fetched At: {{ item.qn_fetched_at }}</p>
                <p>Answered At: {{ item.answered_at }}</p>
                <p>Time Taken: {{ item.time_taken }}</p>
              </div>
               <div class="col-lg-2 text-right">
                   <div class="row">
                       <div>
                        <span v-if="item.ans_result" class="text-success">✔️</span>
                        <span v-else class="text-danger">❌</span></div>
                   <div v-if="!item.ans_result" class="ml-2">
                        <btn size="sm" :class="showChoices === index ? 'fa fa-eye-slash' : 'fa fa-eye'" @click="toggleChoices(index)">

                        </btn>
                       </div>
                       </div>
                   </div>
          </div>
      </div>
          </div>

                <div>
                </div>
            </div>

<!--        <div class="details-container">-->
<!--          <table class="details-table">-->
<!--            <tr>-->
<!--              <td><strong>Candidate</strong></td>-->
<!--              <td><strong>:</strong></td>-->
<!--              <td>{{ selectedResult.candidate_name }}</td>-->
<!--              <td><strong>Total Questions</strong></td>-->
<!--              <td><strong>:</strong></td>-->
<!--              <td>{{ selectedResult.total_questions }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td><strong>Email</strong></td>-->
<!--              <td><strong>:</strong></td>-->
<!--              <td>{{ selectedResult.candidate_email }}</td>-->
<!--              <td><strong>Marks Obtained</strong></td>-->
<!--              <td><strong>:</strong></td>-->
<!--              <td>{{ selectedResult.mark_obtained }}</td>-->
<!--            </tr>-->
<!--          </table>-->

<!--          <div class="skill-grading">-->
<!--            <h3>Skill Grading</h3>-->
<!--            <table class="skill-table">-->
<!--              <thead>-->
<!--                <tr>-->
<!--                  <th>Skill</th>-->
<!--                  <th>Total Questions</th>-->
<!--                  <th>Correct Answers</th>-->
<!--                  <th>Accuracy (%)</th>-->
<!--                </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--                <tr v-for="(data, skill) in parsedSkillGrading" :key="skill">-->
<!--                  <td>{{ skill }}</td>-->
<!--                  <td>{{ data.total_questions }}</td>-->
<!--                  <td>{{ data.correct_answers }}</td>-->
<!--                  <td>{{ data.accuracy }}%</td>-->
<!--                </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--            <div class="details-container">-->
<!--          <table class="details-table">-->
<!--            <tr>-->
<!--                <td><strong>Trait Analysis</strong></td>-->
<!--                <td><strong>:</strong></td>-->
<!--                <td>{{ selectedResult.trait_analysis }}</td>-->
<!--            </tr>-->
<!--              <tr>-->
<!--                <td><strong>Analysis Details</strong></td>-->
<!--                <td><strong>:</strong></td>-->
<!--                <td>{{ selectedResult.analysis_details }}</td>-->
<!--            </tr>-->
<!--              <tr>-->
<!--                <td><strong>Result Percentage</strong></td>-->
<!--                <td><strong>:</strong></td>-->
<!--                <td>{{ selectedResult.result_percentage }}%</td>-->
<!--            </tr>-->
<!--              <tr>-->
<!--                <td><strong>Generated At</strong></td>-->
<!--                <td><strong>:</strong></td>-->
<!--                <td>{{ selectedResult.generated_at }}</td>-->
<!--            </tr>-->
<!--          </table>-->

<!--                </div>-->
<!--             <div class="question-container">-->
<!--      <div v-for="(item, index) in selectedAnswer" :key="item.id" class="question-card">-->
<!--          <div class="row">-->
<!--          <div class="col-lg-10">-->
<!--                <p><b>{{ index + 1 }}. {{ item.question }}</b></p>-->
<!--                   <ul class="options-list" v-if="showChoices===index">-->
<!--                      <li v-for="(choice, choiceIndex) in item.choices" :key="choiceIndex">-->
<!--                        <label>-->
<!--&lt;!&ndash;                          <input type="radio" :name="'question-' + index" :value="choice" />&ndash;&gt;-->
<!--                          - {{ choice }}-->
<!--                        </label>-->
<!--                      </li>-->
<!--                       <p><b>Correct Answer:</b> {{ item.correct_answer }}</p>-->
<!--                    </ul>-->
<!--              <p><b>Answer Given:</b> {{ item.answer }}</p>-->
<!--                <p>Question Fetched At: {{ item.qn_fetched_at }}</p>-->
<!--                <p>Answered At: {{ item.answered_at }}</p>-->
<!--                <p>Time Taken: {{ item.time_taken }}</p>-->
<!--              </div>-->
<!--               <div class="col-lg-2 text-right">-->
<!--                   <div class="row">-->
<!--                       <div>-->
<!--                        <span v-if="item.ans_result" class="text-success">✔️</span>-->
<!--                        <span v-else class="text-danger">❌</span></div>-->
<!--                   <div v-if="!item.ans_result" class="ml-2">-->
<!--                        <btn size="sm" :class="showChoices === index ? 'fa fa-eye-slash' : 'fa fa-eye'" @click="toggleChoices(index)">-->

<!--                        </btn>-->
<!--                       </div>-->
<!--                       </div>-->
<!--                   </div>-->
<!--          </div>-->
<!--      </div>-->
<!--          </div>-->
<!--        </div>-->
      </s-form>
    </modal>
<!--      <modal v-if="selectedAnswer" ref="answerDetails" :no-close-on-backdrop="true" title="Attended Answers" header-color="primary" :show-additional-buttons="true">-->
<!--      <template #buttons>-->
<!--        <a @click="detailsClose" class="close-btn"><i class="fa fa-close"></i></a>-->
<!--      </template>-->
<!--&lt;!&ndash;          {{selectedAnswer}}&ndash;&gt;-->
<!--          <div class="question-container">-->
<!--      <div v-for="(item, index) in selectedAnswer" :key="item.id" class="question-card">-->
<!--          <div class="row">-->
<!--          <div class="col-lg-10">-->
<!--                <p><b>{{ index + 1 }}. {{ item.question }}</b></p>-->
<!--                   <ul class="options-list" v-if="showChoices===index">-->
<!--                      <li v-for="(choice, choiceIndex) in item.choices" :key="choiceIndex">-->
<!--                        <label>-->
<!--&lt;!&ndash;                          <input type="radio" :name="'question-' + index" :value="choice" />&ndash;&gt;-->
<!--                          - {{ choice }}-->
<!--                        </label>-->
<!--                      </li>-->
<!--                       <p><b>Correct Answer:</b> {{ item.correct_answer }}</p>-->
<!--                    </ul>-->
<!--              <p><b>Answer Given:</b> {{ item.answer }}</p>-->
<!--                <p>Question Fetched At: {{ item.qn_fetched_at }}</p>-->
<!--                <p>Answered At: {{ item.answered_at }}</p>-->
<!--                <p>Time Taken: {{ item.time_taken }}</p>-->
<!--              </div>-->
<!--               <div class="col-lg-2 text-right">-->
<!--                   <div class="row">-->
<!--                       <div>-->
<!--                        <span v-if="item.ans_result" class="text-success">✔️</span>-->
<!--                        <span v-else class="text-danger">❌</span></div>-->
<!--                   <div v-if="!item.ans_result" class="ml-2">-->
<!--                        <btn size="sm" :class="showChoices === index ? 'fa fa-eye-slash' : 'fa fa-eye'" @click="toggleChoices(index)">-->

<!--                        </btn>-->
<!--                       </div>-->
<!--                       </div>-->
<!--                   </div>-->
<!--          </div>-->
<!--      </div>-->
<!--          </div>-->

<!--      </modal>-->

  </div>
    <div v-else>
    <p>Loading candidates...</p>
  </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
export default {
    name: 'Candidates',
    showModal: false,
    answers: [],
    loading: false,

    props: {
        interview: {
            type: Number,
            required: true

        }
    },
    data () {
        return {
            candidateSelectUrl: urls.mcq_interview.candidate.select,
            addEditUrl: urls.mcq_interview.interview_candidate.addEdit,
            emailUrl: urls.mcq_interview.interview_candidate.email,
            candidateList: [],
            loadingIndex: null,
            selectedResult: null,
            selectedAnswer: null,
            showChoices: null,
            showChoiceButton: false,
            candidateData: '',
            candidates: '',
            question_filter: 'All',
            result: null,
            resultData: null,
            answerData: null,
            questionFilterOptions: [
                { label: 'All', value: 'All' },
                { label: 'Correct', value: 'Correct' },
                { label: 'Wrong', value: 'Wrong' }

            ]

        };
    },
    mounted () {
        this.loadCandidates();
    },
    methods: {
        candidateAddClose () {
            this.$refs.allocatecandidate.close();
        },
        toggleChoices (index) {
            // Toggle between opening and closing the options
            this.showChoices = this.showChoices === index ? null : index;
        },
        async loadCandidates () {
            this.loading = true;
            console.log('----------- ID---------------------------------', this.interview);
            const response = await axios.form(urls.mcq_interview.interview.candidateDetails, { id: this.interview });
            const json = response.data;
            if (json.error === false) {
                this.candidates = json.data;
                this.candidateData = this.candidates.candidate_data;
                // this.result_data = this.details.result_data;
                console.log('----------- Question Data-----', this.candidateData);
                // console.log('----------- interview-----', typeof (this.details.interview_data.id));
            } else {
                if (json.message) {
                    this.$notify(json.message, 'Error', {
                        type: 'danger'
                    });
                    this.$router.push('/mcq-interview/interview/');
                } else {
                    this.$notify('Could not load data, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
            }
            this.loading = false;
        },

        async candidateAdd () {
            console.log('-----candidates-------------', this.candidateList);
            // console.log('-----interview id-------------', this.interview_id);
            const response = await axios.form(this.addEditUrl, { candidate: this.candidateList, interview_id: this.interview });
            const result = response.data;
            console.log('data------------------------------', result);
            if (result.success) {
                this.$notify('Interview Candidate Added Successfully', 'Success', { type: 'success' });
                this.candidateAddFormSuccess();
            } else {
                if (result.message) {
                    this.$notify(result.message, 'Warning', { type: 'warning' });
                }
                const errors = response.data.errors;

                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        let errorMessage = '';
                        if (key === '__all__') {
                            errorMessage = `${errors[key]}`;
                        } else {
                            errorMessage = `${key}:  ${errors[key]}`;
                        }
                        this.$notify(errorMessage, 'Warning', { type: 'warning' });
                    }
                }
            }
        },
        async emailSend (candidate, interview, interviewCandidate, index) {
            this.loadingIndex = index;
            console.log('candidate------------------------------', candidate);
            console.log('interview------------------------------', interview);
            const response = await axios.form(this.emailUrl, { candidate: candidate, interview_id: interview, interview_candidate: interviewCandidate });
            const result = response.data;
            console.log('data------------------------------', result);
            if (result.success) {
                this.$notify('Email Send Successfully', 'Success', { type: 'success' });
                this.loadingIndex = null;
                this.$emit('reloadInterviewDetails');
            } else {
                if (result.message) {
                    this.$notify(result.message, 'Warning', { type: 'warning' });
                    this.loadingIndex = null;
                }
                const errors = response.data.errors;

                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        let errorMessage = '';
                        if (key === '__all__') {
                            errorMessage = `${errors[key]}`;
                        } else {
                            errorMessage = `${key}:  ${errors[key]}`;
                        }
                        this.$notify(errorMessage, 'Warning', { type: 'warning' });
                    }
                }
                this.loadingIndex = null;
            }
        },

        candidateAddFormSuccess () {
            this.$refs.allocatecandidate.close();
            this.$emit('reloadInterviewDetails');
        },
        async downloadResult (interviewCandidate) {
            this.loading = true;
            try {
                const response = await axios.form(urls.mcq_interview.interview.downloadResults, { candidate: interviewCandidate, ans_filter: this.question_filter, interview: this.interview });
                const json = response.data;
                if (json.error === false) {
                    this.loading = false;
                    window.open(json.url);
                } else {
                    this.loading = false;
                    this.$notify(json.message, 'Oops!', { type: 'warning' });
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async showResultDetails (interviewCandidate) {
            this.loading = true;
            console.log('----------- ID---------------------------------', this.interview);
            const response = await axios.form(urls.mcq_interview.interview.resultDetails, { candidate: interviewCandidate, ans_filter: this.question_filter });
            const json = response.data;
            if (json.error === false) {
                this.result = json.data;
                this.resultData = this.result.result_data;
                this.answerData = this.result.result_data.answers;
            } else {
                if (json.message) {
                    this.$notify(json.message, 'Error', {
                        type: 'danger'
                    });
                    this.$router.push('/mcq-interview/interview/');
                } else {
                    this.$notify('Could not load data, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
            }
            this.loading = false;
            // console.log('Result---------------------------', this.result);
            if (!this.resultData || Object.keys(this.resultData).length === 0) {
                this.$notify('No Result to Show', 'Warning', { type: 'warning' });
                return;
            }
            this.selectedResult = this.result;
            this.$refs.resultDetailsModal.show();
        },

        ViewAnswers (result) {
            console.log('Answers---------------------------', result);
            if (!result || Object.keys(result).length === 0) {
                this.$notify('No Result to Show', 'Warning', { type: 'warning' });
                return;
            }
            this.selectedAnswer = result;
            this.$refs.answerDetails.show();
        },

        detailsClose () {
            this.selectedResult = null;
            this.selectedAnswer = null;
            this.$refs.resultDetailsModal.close();
        }

    },
    computed: {
        parsedSkillGrading () {
            try {
                // Ensure valid JSON format by replacing single quotes with double quotes
                const validJson = this.resultData.skill_grading.replace(/'/g, '"');
                return JSON.parse(validJson);
            } catch (e) {
                console.error('Error parsing skill grading:', e);
                return {};
            }
        }
    }
};
</script>

<style scoped>
.skill-grading {
  margin-top: 15px;
}

.skill-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.skill-table th,
.skill-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.skill-table th {
  background-color: #f1f1f1;
  font-weight: bold;
}
.details-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.details-table {
  width: 100%;
  margin-bottom: 15px;
}

.details-table td {
  padding: 8px;
}

</style>
