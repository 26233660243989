<template>
  <div v-if="details">
      <div class="text-right">
                          <btn size="sm" text="Allocate Topic" icon="fa fa-plus"
                     @click="$refs.allocateTopic.show();" :loading="loading"></btn>
                     </div>
          <modal ref="allocateTopic" :no-close-on-backdrop="true" title="Topics" width="40r" header-color="primary" :show-additional-buttons="true">
            <template #buttons>
                <a @click="TopicAddClose"><i class="fa fa-close"></i></a>
            </template>

            <s-form @submit="TopicAdd">
                <div>
                        <validated-ajax-vue-select class="c-input-select field-required" :rules="{required: true}"
                                               :url="interviewTypeOptionsURL"
                                               label="Interview Type" v-model="model.type"></validated-ajax-vue-select>
                    </div>
                <div>
                    <validated-ajax-vue-select :url="topicSelectUrl" @input="loadSubTopicOption" label="Topic" v-model="model.topic"></validated-ajax-vue-select>

                </div>
                <div>
                    <validated-vue-select :options="subTopicOption" label="Sub Topic" v-model="model.sub_topic"></validated-vue-select>

                </div>

                    <div>
                        <validated-input label="No of Questions" :rules="{required: true}"
                                         class="field-required" border-radius="1" v-model="model.no_of_questions " type="number"></validated-input>
                    </div>
                 <div class="col-12 text-right mt-2">
                        <div class="btn-group">
                           <btn text="Add" @click="AddInterviewTopic" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                        </div>
                        </div>
            </s-form>
          </modal>
      <delete-modal ref="deleteModal" enter-animation="animated slideInDown" exit-animation="animated slideOutUp" :url="deleteURL" :params="{id : itemID}"
                      @response="deleteComplete">
            <p> Are you sure ?</p>
            <template #loading>
                <div class="fl-x">
                    <loading-animation/>
                    <b class="ml-3">Please Wait...</b>
                </div>
            </template>
        </delete-modal>

<!--    <h2 class="text-xl font-bold mb-4">Interview Results</h2>-->
    <table class="full-width">
      <thead>
        <tr class="tc-heading-center">
          <th class="border border-gray-300 px-4 py-2">Type</th>
          <th class="border border-gray-300 px-4 py-2">Topic</th>
          <th class="border border-gray-300 px-4 py-2">Sub Topic</th>
          <th class="border border-gray-300 px-4 py-2">No of questions</th>
          <th class="border border-gray-300 px-4 py-2">Action</th>

        </tr>
      </thead>
      <tbody>
        <tr v-for="(result, index) in details" :key="index">
          <td class="border border-gray-300 px-4 py-2">{{ result.type_name }}</td>
          <td class="border border-gray-300 px-4 py-2">{{ result.topic_name }}</td>
          <td class="border border-gray-300 px-4 py-2">{{ result.sub_topic_name }}</td>
          <td class="border border-gray-300 px-4 py-2">{{ result.no_of_questions }}</td>
            <td align="center">
                <div class="btn-group">
                    <btn size="sm" v-if="!result.qn_generated_flag" text="Generate Question" icon="fa fa-plus" @click="GenerateQuestion(result.type, result.topic,result.sub_topic,result.interview_id,result.no_of_questions, index)" :loading="loadingIndex === index"  loading-text="Generating..."></btn>
                     <btn text="" size="sm" icon="fa fa-trash" color="danger"
                                     @click="deleteItem(result.id)"/>
                </div>
            </td>

        </tr>
      </tbody>
    </table>
  </div>
    <div v-else>
    <p>Loading results...</p>

  </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
export default {
    name: 'Topics',
    loading: false,
    props: {
        details: {
            type: Array,
            default: () => []
        },
        interview: {
            type: Number,
            required: true

        }
    },
    data () {
        return {
            topicSelectUrl: urls.mcq_interview.interview_topic.select,
            subTopicSelectUrl: urls.mcq_interview.interview_sub_topic.select,
            addEditUrl: urls.mcq_interview.interviewAllocation.create,
            deleteURL: urls.mcq_interview.interviewAllocation.delete,
            interviewTypeOptionsURL: urls.mcq_interview.interview_type.select,
            subTopicOption: [],
            loadingIndex: null,
            selectedResult: null,
            selectedAnswer: null,
            itemID: null,
            model: {
                sub_topic: null,
                topic: null,
                type: null,
                no_of_questions: null,
                interview: this.interview
            }

        };
    },

    methods: {
        TopicAddClose () {
            this.$refs.allocateTopic.close();
        },
        async loadSubTopicOption () {
            const response = await axios.get(urls.mcq_interview.interview_sub_topic.select + '?topic=' + this.model.topic);
            this.subTopicOption = response.data.data;
        },
        async AddInterviewTopic () {
            this.loading = true;
            console.log('-----Topics-------------', this.TopicList);
            // console.log('-----interview id-------------', this.interview_id);
            const response = await axios.form(this.addEditUrl, this.model);
            const result = response.data;
            console.log('data------------------------------', result);
            if (result.success) {
                this.$notify('Interview Topic Added Successfully', 'Success', { type: 'success' });
                this.TopicAddFormSuccess();
                this.loading = false;
            } else {
                if (result.message) {
                    this.$notify(result.message, 'Warning', { type: 'warning' });
                    this.loading = false;
                }
                const errors = response.data.errors;

                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        let errorMessage = '';
                        if (key === '__all__') {
                            errorMessage = `${errors[key]}`;
                        } else {
                            errorMessage = `${key}:  ${errors[key]}`;
                        }
                        this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        this.loading = false;
                    }
                }
            }
            this.loading = false;
        },
        async GenerateQuestion (type, topic, subtopic, interview, noofquestions, index) {
            this.loadingIndex = index;
            try {
                const response = await axios.form(urls.mcq_interview.interview.generateQuestions, {
                    type: type,
                    topic: topic,
                    sub_topic: subtopic,
                    interview_id: interview,
                    no_of_questions: noofquestions
                });
                const result = response.data;
                if (result.success) {
                    this.$notify(' Questions Generated Successfully', 'Success', { type: 'success' });
                    this.loadingIndex = null;
                    this.$emit('reloadInterviewDetails');
                } else {
                    if (result.message) {
                        this.$notify(result.message, 'Warning', { type: 'warning' });
                        this.loadingIndex = null;
                    }
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loadingIndex = null;
                }
            } catch {
                this.$notify('Something Went Wrong...', 'Warning', { type: 'danger' });
                this.loadingIndex = null;
            }
        },
        deleteItem (item) {
            this.itemID = item;
            this.$refs.deleteModal.show();
        },

        TopicAddFormSuccess () {
            this.$refs.allocateTopic.close();
            this.model.topic = null;
            this.model.type = null;
            this.model.sub_topic = null;
            this.model.no_of_questions = null;
            this.$emit('reloadInterviewDetails');
        },
        deleteComplete () {
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.deleteModal.close();
            this.$emit('reloadInterviewDetails');
        }

    }
};
</script>

<style scoped>
.skill-grading {
  margin-top: 15px;
}

.skill-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.skill-table th,
.skill-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.skill-table th {
  background-color: #f1f1f1;
  font-weight: bold;
}
.details-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.details-table {
  width: 100%;
  margin-bottom: 15px;
}

.details-table td {
  padding: 8px;
}

</style>
